@import '../../../styles/variables';
@import '../../../styles/mixins';

.authLayout {
  width: 100%;
  min-height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  .backdrop {
    width: 100%;
    height: 100%;
    background-color: $auth-backdrop-background-color;
    backdrop-filter: blur(0.188rem);

    .content {
      width: 100%;
      min-height: 100vh;

      .logo {
        padding-top: 0.625rem;
        padding-left: 6.25rem;

        > img {
          width: 5.9356rem;
          height: 5.0625rem;
        }
      }

      .contentInner {
        margin: 0rem 1rem;

        @include bp(xs) {
          width: 24.125rem;
          margin-left: auto;
          margin-right: auto;
          padding-bottom: 1rem;
        }

        .backButtonContainer {
          display: flex;
          flex-direction: row;
          align-items: center;
          cursor: pointer;
          color: $white-color;
          gap: 0.5rem;

          &:hover {
            .backButtonIcon {
              background-color: $icon-button-background;
            }

            span {
              transform: scale(1.01);
            }
          }

          .backButtonIcon {
            width: 1.5rem;
            height: 1.5rem;
            background-color: $white-color;
            color: $black-color;
            font-size: 1rem;
          }
        }

        .innerCard {
          padding: 2rem 1rem;
          background-color: $white-color;
          border-radius: 0.75rem;
          margin-top: 1rem;
          border: 0.0625rem solid $card-border;
          box-shadow: 0 0 1.25rem 0 $very-light-black-color;
          overflow: hidden;

          @include bp(xs) {
            padding: 2rem 2.25rem;
          }
        }
      }
    }
  }
}
