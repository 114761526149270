@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.newsletterSubscribeModal {
  display: flex;
  font-family: $robotoFont;

  .img {
    display: none;
    width: 33rem;
    border-radius: 0.75rem;

    @include bp(ml) {
      display: block;
    }
  }

  .infoSection {
    display: flex;
    flex-direction: column;
    max-width: 15rem;
    margin-top: 2rem;

    @include bp(xxs) {
      max-width: 19.5625rem;
      margin-top: 2rem;
    }

    @include bp(sm) {
      margin-top: 0rem;
    }

    .icon {
      display: flex;
      justify-content: center;
      margin-bottom: 1.75rem;
    }

    .title {
      margin-top: 0;
      margin-bottom: 1.5rem;
      font-size: 2rem;
      font-weight: 700;
      line-height: 2rem;
      text-align: left !important;

      @include bp(xxs) {
        line-height: 3.0762rem;
        font-size: 2.625rem;
      }
    }

    .description {
      margin-top: 0;
      margin-bottom: 2rem;
      color: $black-color;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.1rem;
      text-align: left;

      @include bp(xxs) {
        font-size: 1.125rem;
        line-height: 1.3181rem;
      }
    }
  }

  .form {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .formInputs {
      display: flex;
      flex-direction: column;
      gap: 1.125rem;
      text-align: left;
    }

    > div {
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      @include bp(m) {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  :global(.MuiPaper-root) {
    :global(.MuiDialogContent-root) {
      gap: 2.75rem;
      padding: 0.5rem 1.75rem 1.75rem 1.75rem !important;

      @include bp(xs) {
        padding: 0.5rem 2.75rem 2.75rem 2.75rem !important;
      }

      @include bp(sm) {
        display: flex;
        flex-direction: row;
        padding: 2.75rem 2.75rem 2.75rem 2.75rem !important;
      }
    }
  }

  :global(.MuiDialog-container) {
    margin: 0 auto;
  }

  :global(.MuiDialog-paperScrollPaper) {
    min-width: auto !important;
  }
}
