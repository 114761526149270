@import "../../styles/variables";
@import "../../styles/mixins";

.container {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.rightSection {
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include bp(m) {
    padding: 0 1.5rem;
  }

  &.noScroll {
    overflow: hidden;
  }

  .content {
    padding: 0 1.5rem 2.5rem 1.5rem;
    margin-top: 2.5rem;

    @include bp(m) {
      margin-top: 5rem;
      padding: 2.5rem 0 2.5rem 0;
    }
  }
}

.mobileDrawer {
  top: 5rem;
  position: absolute;
  width: 100%;
  background-color: rgba(255, 255, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  z-index: 8;
  box-shadow: rgba(34, 51, 84, 0.2) 0 0.125rem 0.5rem -0.188rem, rgba(34, 51, 84, 0.1) 0 0.313rem 1.375rem -0.25rem;

  .navigationGroup {
    width: 100%;
    margin-top: 1.25rem;

    .groupName {
      box-sizing: border-box;
      list-style: none;
      font-family: $globalFont;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.75rem;
      color: $black-color;
      line-height: 1.4;
      padding: 0 1.406rem 0.5rem;
    }

    .navigationItem {
      display: flex;
      color: $color-grey;
      width: 100%;
      justify-content: flex-start;
      padding: 0.875rem 1.5rem;
      align-items: center;
      position: relative;
      box-sizing: border-box;
      outline: 0;
      border: 0;
      cursor: pointer;
      user-select: none;
      vertical-align: middle;
      appearance: none;
      text-decoration: none;
      font-family: $globalFont;
      font-size: 0.875rem;
      line-height: 1.75;
      min-width: 4rem;
      border-radius: 0;
      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      font-weight: 500;
      text-transform: none;
      margin: 0 0 0.25rem;

      &:hover {
        background-color: rgba(25, 118, 210, 0.06);
      }

      &.activeSubItem {
        background-color: rgba(25, 118, 210, 0.12);
        color: $primary-main;
        font-weight: 600;
      }

      .navigationIcon {
        margin-right: 0.625rem;
      }
    }
  }
}

.branchSelectModal {
  :global(.MuiDialog-paperScrollPaper) {
    max-width: 37.5rem !important;
  }
}

.publicContainer {
  min-height: calc(100vh - 4.5rem);

  &.minPublicContainer {
    min-height: 0;

    @include bp(m) {
      min-height: calc(100vh - 12rem);
    }
  }
}
