@import '../../../styles/variables';
@import '../../../styles/mixins';

.mainLayout {
  .header {
    height: 5.375rem;
    background-color: $white-color;

    @include bp(m) {
      height: 6.25rem;
    }

    .headerInner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      margin-inline: auto;
      padding-inline: 1rem;

      @include bp(xs) {
        padding-inline: 2rem;
      }

      @include bp(xl) {
        padding-inline: 4.8rem;
      }

      @include bp(xxl) {
        padding-inline: 7.5rem;
      }

      .logoContainer {
        width: 4.375rem;
        height: auto;

        @include bp(m) {
          width: 6.188rem;
          height: auto;
        }

        img {
          cursor: pointer;
          width: 4.375rem;
          height: auto;

          @include bp(m) {
            width: 6.188rem;
            height: auto;
          }
        }
      }

      .navigationItems {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;

        .topLinks {
          display: flex;
          flex-direction: row;
          gap: 1.5rem;

          a {
            font-family: $globalFont;
            font-size: 1.125rem;
            color: $black-color;
            font-weight: 400;
            line-height: normal;
            font-style: normal;
            cursor: pointer;

            &:hover {
              transform: scale(1.05);
            }
          }
        }

        .topButtons {
          display: flex;
          flex-direction: row;
          gap: 0.75rem;

          .authIcons {
            display: flex;
            flex-direction: row;
            gap: 0.75rem;
            align-items: center;
            justify-content: center;
            padding-left: 0.5rem;

            img {
              cursor: pointer;

              &:hover {
                transform: scale(1.1);
              }
            }
          }
        }
      }

      .mobileNavigationButtons {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.7rem;
        margin-left: 0.8rem;
        
        @include bp(xxs) {
          margin-left: 1rem;
          gap: 1.25rem;
        }

        .reserveButton {
          font-size: 0.875rem;

          @include bp(xxs) {
            font-size: 1rem;
          }
        }

        .iconContainer {
          background-color: $icon-button-background;
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 0.25rem;
          color: $primary-light;
        }
      }
    }

    .drawerContainer {
      background-color: $white-color;
      border-top: 1px solid $drawer-border-color;
      border-bottom: 1px solid $drawer-border-color;
      width: 100%;
      position: absolute;
      display: flex;
      flex-direction: column;
      z-index: 10;

      .drawerNavigationBack {
        padding: 1.25rem 1rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: $globalFont;
        font-size: 0.875rem;
        gap: 0.313rem;
        color: $secondary-color;
        font-weight: 500;
        line-height: normal;
        font-style: normal;
        border-bottom: 1px solid $drawer-border-color;

        &:hover {
          background-color: $icon-button-background;
        }
      }

      .drawerNavigationItem {
        padding: 1.25rem 1rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $drawer-border-color;
        font-family: $globalFont;
        font-size: 0.875rem;
        color: $black-color;
        font-weight: 500;
        line-height: normal;
        font-style: normal;

        &:hover {
          background-color: $icon-button-background;
        }
      }

      .drawerButton {
        padding-left: 1rem;
        padding-right: 1rem;
        width: 100%;
        display: flex;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;

        button {
          width: 100%;
        }

        .authDrawerItems {
          display: flex;
          justify-content: center;
          width: 100%;
          gap: 0.5rem;
        }
      }
    }
  }

  .content {
  }
}

.profilePopover {
  :global(.MuiPopover-paper) {
    border-radius: 0.5rem;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  }

  .popoverContent {
    .popoverList {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;

      .popoverNavigationItem {
        padding: 1.25rem 1rem;
        width: 13.313rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $drawer-border-color;
        font-family: $globalFont;
        font-size: 0.875rem;
        color: $black-color;
        font-weight: 500;
        line-height: normal;
        font-style: normal;

        &:hover {
          background-color: $icon-button-background;
        }
      }
    }

    .popoverAuthActions {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      padding: 1rem;
    }
  }
}
