@mixin bp($point) {
  @if $point == xxs {
    @media (min-width: 25rem) {
      @content;
    }
  } @else if $point == xs {
    @media (min-width: 31.25rem) {
      @content;
    }
  } @else if $point == s {
    @media (min-width: 37.5rem) {
      @content;
    }
  } @else if $point == sm {
    @media (min-width: 46.875rem) {
      @content;
    }
  } @else if $point == m {
    @media (min-width: 56.25rem) {
      @content;
    }
  } @else if $point == ml {
    @media (min-width: 62.5rem) {
      @content;
    }
  } @else if $point == l {
    @media (min-width: 75rem) {
      @content;
    }
  } @else if $point == xl {
    @media (min-width: 87.5rem) {
      @content;
    }
  } @else if $point == xxl {
    @media (min-width: 100rem) {
      @content;
    }
  } @else if $point == xxxl {
    @media (min-width: 112.5rem) {
      @content;
    }
  }
}
