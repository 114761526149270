@import '../../styles/variables';
@import '../../styles/mixins';

.button {
  min-height: 2.5rem;
  transition: $button-transition;
  color: $bright-color;

  @include bp(m) {
    min-height: 3.25rem;
  }

  &:global(.MuiButton-containedPrimary) {
    background-color: $black-color;
    border: 0.0625rem solid $black-color;
    box-shadow: none;
    border-radius: 6.25rem;
    padding: 1rem 1.25rem;
    font-family: $globalFont;
    text-transform: none;
    font-size: 1rem;
    line-height: 1.3125rem;
    font-weight: 500;

    &:global(.Mui-disabled) {
      color: $bright-color;
      box-shadow: none;
      background-color: $button-disabled;
      border: none;
    }

    &:hover {
      box-shadow: none;
      background-color: $primary-button-background-hover;
    }

    @include bp(m) {
      font-size: 1.125rem;
      padding: 1rem 1.5rem;
    }
  }

  &:global(.MuiButton-containedSecondary) {
    background-color: $bright-color;
    box-shadow: none;
    border-radius: 6.25rem;
    border: 0.0625rem solid $black-color;
    padding: 1rem 1.25rem;
    font-family: $globalFont;
    text-transform: none;
    font-size: 1rem;
    line-height: 1.3125rem;
    font-weight: 500;
    color: $black-color;
    height: 2.5rem;

    @include bp(m) {
      height: 3.25rem;
      font-size: 1.125rem;
      padding: 1rem 1.5rem;
    }

    &:global(.Mui-disabled) {
      box-shadow: none;
      background-color: $button-disabled;
    }

    &:hover {
      border: 0.0625rem solid $secondary-button-border-hover;
      background-color: $secondary-button-background-hover;
      box-shadow: none;
      color: $secondary-button-text;
    }

    &:global(.Mui-disabled) {
      color: $button-disabled;
      border: 0.0625rem solid $button-disabled;
      box-shadow: none;
      background-color: $bright-color;
    }
  }

  &:global(.MuiButton-textPrimary) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    background-color: transparent;
    outline: 0;
    border: 0;
    margin: 0;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
    font-weight: 700;
    line-height: 1.71429;
    font-size: 1rem;
    text-transform: inherit;
    font-family: $globalFont;
    min-width: 4rem;
    padding: 0.375rem 0.5rem;
    border-radius: 0.5rem;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: $primary-button-background;
  }

  &.simpleButton {
    max-height: 3.25rem;
  }

  &.saveButton {
    height: auto;
    padding: 0.375rem 3rem;
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.textButton {
    opacity: 0.8;
  }

  &.isActive {
    opacity: 1;
  }

  &.onlyIcon {
    padding-left: 1rem;
    padding-right: 1rem;
    min-width: 2.5rem;

    :global(.MuiButton-startIcon) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .loadingIcon {
    margin-right: 0.25rem;
  }
}
