@import '../../styles/variables';
@import '../../styles/mixins';

.textFieldPublic {
  width: 100%;

  :global(.MuiAutocomplete-inputRoot) {
    padding-right: 0.563rem !important;
  }

  :global(.MuiAutocomplete-root) {
    width: 100%;
  }

  :global(.MuiInputBase-root) {
    background-color: transparent;
  }

  :global(.MuiInputBase-root:hover) {
    border-radius: 0.5rem;
  }

  &.currencyInput {
    p {
      margin: 0.063rem 0 0;
      line-height: 1.5;
      font-family: $globalFont;
      font-weight: 400;
      color: rgb(99, 115, 129);

      &:global(.Mui-error) {
        color: $input-error-text;
        margin: 0.5rem 0.875rem 0;
        font-size: 0.75rem;
        line-height: 1.66;
      }
    }
  }

  &.timeInput {
    :global(.MuiFormHelperText-sizeMedium) {
      position: absolute;
      top: 3.125rem;
    }
  }

  label {
    font-family: $globalFont;
    color: $label;
    font-size: 0.875rem;
    line-height: 1.4374em;
    font-weight: 400;
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 1.5rem);
    position: absolute;

    &:global(.Mui-focused) {
      color: $label-focused;
    }

    &:global(.Mui-error) {
      color: $label-error;
    }
  }

  > div {
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-family: $globalFont;
    font-weight: 400;
    line-height: 1.4375;

    input {
      font-family: $globalFont;
    }

    fieldset {
      border-color: $input-border;

      legend {
        width: auto;
      }
    }

    &:global(.Mui-focused) {
      fieldset {
        border-color: $input-border-focused !important;
      }
    }

    &:global(.Mui-error) {
      fieldset {
        border-color: $input-border-error !important;
      }
    }
  }

  p {
    font-family: $globalFont;
    margin: 0.5rem 0.875rem 0;

    &:global(.Mui-error) {
      color: $input-error-text;
    }
  }
}

.switcherContainer {
  margin-top: 0.625rem;

  :global(.MuiSwitch-switchBase) {
    color: $red-color;
  }

  :global(.Mui-checked) {
    color: rgb(0, 171, 85) !important;
  }

  :global(.Mui-checked + .MuiSwitch-track) {
    background-color: rgb(0, 171, 85) !important;
  }

  :global(.MuiSwitch-track) {
    background-color: $red-color;
  }

  :global(.MuiFormControlLabel-label) {
    line-height: 1.57143;
    font-size: 0.875rem;
    font-family: $globalFont;
    font-weight: 400;
  }
}

.checkboxContainer {
  width: 100%;
  margin-left: 0 !important;
  margin-right: 0 !important;

  :global(.MuiSvgIcon-root) {
    width: 1rem;
    height: 1rem;
  }

  :global(.MuiFormControlLabel-label) {
    font-family: $robotoFont;
    font-weight: 300;
    color: $primary-extra-dark;
    font-size: 1rem;
  }

  :global(.Mui-checked) {
    color: $primary-extra-dark !important;
  }

  &:hover {
    background-color: $very-light-green;
  }
}

.checkboxError {
  color: $red-color !important;
  font-size: 0.75rem;
  margin: 0.5rem 0.875rem 0;
  text-align: left;
}

.editorContainer {
  &.error {
    div:first-of-type {
      border-color: $red-color;
    }
  }

  .editorError {
    font-family: $globalFont;
    font-weight: 400;
    font-size: 0.75rem;
    color: $red-color;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 0.5rem 0.875rem 0;
  }
}

.signatureInputContainer {
  display: flex;
  flex-direction: column;

  .signatureContainer {
    display: flex;
    border-radius: 0.5rem;
    border-style: solid;
    border-width: 0.063rem;
    border-color: $input-border-color;
    padding: 0.063rem;
    position: relative;

    .canvas {
      border-radius: 0.5rem;
    }

    .label {
      position: absolute;
      font-family: $globalFont;
      font-weight: 400;
      font-size: 0.75em;
      line-height: 1.4374em;
      color: $input-color;
      top: -0.625rem;
      left: 0.5rem;
      padding-left: 0.313rem;
      padding-right: 0.313rem;
      background: white;
    }

    .clearButton {
      cursor: pointer;
      position: absolute;
      left: calc(100% - 1.5rem);
    }

    &.error {
      border-color: $red-color;

      .label {
        color: $red-color;
      }
    }

    &:hover {
      border-color: $input-hover-color;
    }

    &:hover > .label {
      color: $input-hover-color;
    }
  }

  .signatureError {
    font-family: $globalFont;
    font-weight: 400;
    font-size: 0.75rem;
    color: $red-color;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin: 0.5rem 0.875rem 0;
  }
}

.iconSelect {
  margin-right: 1.125rem;
  color: $primary-main;
}

.autocomplete {
  width: 100%;
}

.label {
  font-family: $robotoFont;
  font-size: 0.875rem;
  font-weight: 400;
  margin-bottom: 0.5625rem;
}

.eyeIcon {
  cursor: pointer;

  div {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.dropdownIcon {
  padding-right: 0.6875rem;

  div {
    display: flex;
    width: 100%;
    height: 100%;

    > svg {
      max-width: 0.5625rem;
      max-height: 0.4375rem;
      width: 100%;
      height: 100%;
    }
  }
}

.textField {
  width: 100%;

  :global(.MuiAutocomplete-inputRoot) {
    padding-right: 0.563rem !important;
  }

  :global(.MuiAutocomplete-root) {
    width: 100%;
  }

  :global(.MuiInputBase-root) {
    background-color: transparent;
  }

  :global(.MuiInputBase-root:hover) {
    border-radius: 0.5rem;
  }

  &.currencyInput {
    p {
      margin: 0.063rem 0 0;
      line-height: 1.5;
      font-family: $globalFont;
      font-weight: 400;
      color: rgb(99, 115, 129);

      &:global(.Mui-error) {
        color: $input-error-text;
        margin: 0.5rem 0.875rem 0;
        font-size: 0.75rem;
        line-height: 1.66;
      }
    }
  }

  label {
    font-family: $globalFont;
    color: $label;
    font-size: 0.875rem;
    line-height: 1.4374em;
    font-weight: 400;
    padding: 0;
    display: block;
    transform-origin: left top;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(133% - 1.5rem);
    position: absolute;

    &:global(.Mui-focused) {
      color: $label-focused;
    }

    &:global(.Mui-error) {
      color: $label-error;
    }
  }

  > div {
    border-radius: 0.5rem;
    font-size: 0.875rem;
    font-family: $globalFont;
    font-weight: 400;
    line-height: 1.4375;

    input {
      font-family: $globalFont;
    }

    fieldset {
      border-color: $input-border;

      legend {
        width: auto;
      }
    }

    &:global(.Mui-focused) {
      fieldset {
        border-color: $input-border-focused-admin !important;
      }
    }

    &:global(.Mui-error) {
      fieldset {
        border-color: $input-border-error !important;
      }
    }
  }

  p {
    font-family: $globalFont;
    margin: 0.5rem 0.875rem 0;

    &:global(.Mui-error) {
      color: $input-error-text;
    }
  }
}
