@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.footer {
  width: 100%;
  border-top: 1px solid $black-color-border;

  .footerInner {
    display: flex;
    flex-direction: column;
    max-width: 90rem;
    margin-left: auto;
    margin-right: auto;
    padding-inline: 1rem;

    @include bp(xs) {
      padding-inline: 2rem;
    }

    @include bp(m) {
      padding-inline: 6rem;
    }

    .footerReserveTimeContainer {
      padding-top: 3.25rem;

      .footerReserveTimeInner {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $black-color-border;
        padding-bottom: 2.875rem;
        gap: 1.5rem;

        @include bp(l) {
          flex-direction: row;
        }

        .footerReservationText {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          @include bp(l) {
            gap: 2rem;
          }

          .footerTitle {
            font-family: $globalFont;
            font-size: 2rem;
            color: $black-color;
            font-weight: 700;
            line-height: normal;
            font-style: normal;

            @include bp(l) {
              font-size: 2.625rem;
            }
          }

          .footerSubtitle {
            font-family: $globalFont;
            font-size: 1rem;
            color: $black-color;
            font-weight: 400;
            line-height: normal;
            font-style: normal;
          }
        }

        .footerReservationButton {
          z-index: 1;
        }
      }
    }

    .footerBottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-top: 1.375rem;
      padding-bottom: 0.3125rem;
      gap: 1.5rem;

      @include bp(l) {
        flex-direction: row;
      }

      .footerBottomLinksContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.75rem;

        .socialsContainer {
          display: flex;
          flex-direction: row;
          gap: 0.875rem;

          .socialContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 2.438rem;
            width: 2.438rem;
            border-radius: 100%;
            border: 1px solid $primary-color-border;
            transition: background-color 0.2s ease, transform 0.2s ease;

            &:hover {
              background-color: $hover-primary-color;
            }

            img {
              transition: inherit;
            }
          }
        }

        .contacts {
          display: flex;
          flex-direction: row;
          gap: 1rem;

          @include bp(l) {
            gap: 2rem;
          }

          .contactLink {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.25rem;
            font-family: $globalFont;
            font-size: 1rem;
            color: $black-color;
            font-weight: 400;
            line-height: normal;
            font-style: normal;
            transition: transform 0.2s ease, color 0.2s ease;

            &:hover {
              transform: scale(1.05);
            }

            @include bp(l) {
              gap: 0.5rem;
            }

            img,
            span {
              transition: inherit;
            }

            img:hover,
            span:hover {
              transform: none;
              color: inherit;
            }

            span {
              display: none;

              @include bp(l) {
                display: block;
              }
            }
          }
        }
      }

      .footerLogosContainer {
        display: flex;
        flex-direction: row;
        gap: 1.875rem;
      }
      .partnerContainer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 0.375rem;

        img {
          width: 8.875rem;
          height: 1.6875rem;
        }

        span {
          font-family: $globalFont;
          font-size: 1rem;
          color: $partner-color;
          font-weight: 400;
          line-height: normal;
          font-style: normal;
        }
      }
    }

    .links {
      padding-bottom: 1.688rem;
      display: flex;
      align-items: center;
      gap: 0.75rem;

      .divider {
        width: 0.25rem;
        height: 0.25rem;
        background-color: $color-grey;
        border-radius: 50%;
      }

      .link {
        font-family: $robotoFont;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.0256rem;
      }
    }
  }
}
