@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.authCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.688rem;
  background-color: $icon-button-background;
  padding: 0.875rem 0.813rem;
  border-radius: 0.5rem;

  .remainingIcon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .remainingText {
    display: flex;
    flex-direction: column;
    gap: 0.313rem;

    .remainingTitle {
      font-family: $globalFont;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      color: $benefit-title;
    }

    .remainingSubTitle {
      font-family: $globalFont;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      color: $secondary-color;
    }
  }
}
