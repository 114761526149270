@import "../../styles/mixins";
@import "../../styles/variables";

.alert {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: none;
  background-image: none;
  line-height: 1.57143;
  font-size: 0.875rem;
  font-family: $globalFont;
  font-weight: 400;
  border-radius: 0.5rem;
  display: flex;
  padding: 0.375rem 0.75rem;

  &.danger {
    color: $error-text;
    background-color: $error-background;

    .icon {
      color: $error-icon;
    }
  }

  &.warning {
    color: rgb(122, 79, 1);
    background-color: rgb(255, 247, 205);

    .icon {
      color: rgb(255, 193, 7);
    }
  }

  &.info {
    color: rgb(4, 41, 122);
    background-color: rgb(208, 242, 255);

    .icon {
      color: rgb(24, 144, 255);
    }
  }

  &.success {
    color: $bright-color;
    background-color: $primary-light;

    .icon {
      color: $bright-color;
    }
  }

  .icon {
    margin-right: 0.75rem;
    padding: 0.438rem 0;
    display: flex;
    font-size: 1.375rem;
    opacity: 0.9;
  }

  .message {
    padding: 0.5rem 0;
  }
}
