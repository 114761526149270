@import "../../styles/variables";
@import "../../styles/mixins";

.errorFallback {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;

  .icon {
    color: $primary-light;

    &.errorIcon {
      color: $error-icon;
    }
  }

  .errorMessage {
    font-family: $poppinsFont;
    font-weight: 500;
    font-size: 1.125rem;
    margin-top: 1rem;
  }

  .refresh {
    font-family: $poppinsFont;
    font-weight: 500;
    font-size: 1rem;
    color: $primary-main;
    margin-top: 0.625rem;
    cursor: pointer;
  }
}
