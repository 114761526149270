@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  .formInputs {
    display: flex;
    flex-direction: column;
    gap: 1.125rem;
    text-align: left;
  }

  > div {
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    @include bp(m) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .submitButton {
    margin-top: 1.75rem;
  }
}
