@import "../../styles/variables";
@import "../../styles/mixins";

.navigationContainer {
  display: flex;
  align-items: center;
  color: $primary-main;
  padding: 1.25rem 1.125rem;
  right: 0;
  z-index: 8;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(0.188rem);
  justify-content: space-between;
  box-shadow: rgba(34, 51, 84, 0.2) 0 0.125rem 0.5rem -0.188rem, rgba(34, 51, 84, 0.1) 0 0.313rem 1.375rem -0.25rem;
  width: 100%;

  @include bp (m) {
    height: 5rem;
    left: 18.125rem;
    padding: 0 1.125rem;
    position: fixed;
    width: calc(100% - 18.125rem);
  }

  .iconItems {
    display: flex;
    align-items: center;

    button {
      color: $primary-main;
    }
  }
}
