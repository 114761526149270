$poppinsFont: 'Poppins', sans-serif;
$robotoFont: 'Roboto', sans-serif;
$calibriFont: 'Calibri', sans-serif;
$globalFont: $robotoFont;

$error-text: rgb(122, 12, 46);
$error-background: rgb(255, 231, 217);
$error-icon: rgb(255, 72, 66);

$primary-light: #2d5452;
$primary-main: #223d3c;
$primary-dark: #1c3130;
$primary-extra-dark: #1e2423;

$primary-button-background: #2d5452;
$primary-button-background-hover: #1c5158;
$primary-button-shadow: rgba(34, 61, 60, 0.24) 0 0.5rem 1rem 0;

$secondary-button-background-hover: #9bd2ce;
$secondary-button-border-hover: #1c5158;
$secondary-button-text: rgba(28, 81, 88, 1);

$secondary-color: #1c5158;

$button-disabled: rgba(0, 0, 0, 0.2);

$button-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

$color-grey: rgb(126, 136, 143);
$color-grey-light: rgb(218, 222, 227);
$color-grey-text: #d9d9d9;
$color-grey-icon: #131a29;
$color-grey-border: #ededed;
$color-orange: #e9471d;
$color-orange-darker: #ee4216;
$color-orange-darkest: #c7350f;
$color-white: #ffffff;

$label: rgb(145, 158, 171);
$label-focused: $primary-light;
$label-error: #d32f2f;

$input-border: rgba(173, 173, 173, 1);
$input-border-focused: rgba(155, 210, 206, 1);
$input-border-error: $label-error;
$input-error-text: $label-error;

$background-color: #e8e9eb;
$dark-background: #333333;

$separator-color: rgba(227, 87, 36, 0.2);
$input-border-color: rgba(227, 87, 36, 0.5);
$placeholder-color: rgba(227, 87, 36, 0.6);

$bright-color: #ffffff;
$white-color: #ffffff;
$black-color: #000000;

$black-color-border: rgba(0, 0, 0, 0.2);
$primary-color-border: rgba(28, 81, 88, 0.25);

$hover-primary-color: #9bd2ce;
$partner-color: rgba(0, 0, 0, 0.7);

$icon-button-background: rgba(155, 210, 206, 0.3);

$red-color: #e13119;
$red-color-darker: #e04321;
$red-color-brighter: #efc5bd;

$drawer-border-color: #e6e6e6;

$green: rgb(34, 154, 22);
$light-green: #b6d0ad;
$very-light-green: #eef6df;

$warning-color: #f19a3e;
$warning-color-brighter: #f6c48d;
$warning-color-darker: #98540b;

$sidebar-color: #313638;
$navigation-color: #313638;

$input-color: #919eab;
$input-border-color: rgba(145, 158, 171, 0.32);
$input-hover-color: rgba(0, 0, 0, 0.87);
$input-focus-color: #2d5452;

$calendar-shadow: #919eab;

$benefit-title: #161634;
$benefit-subtitle: rgba(0, 0, 0, 0.7);

$section-background: rgba(244, 245, 246, 0.75);

$card-border: #e8e8e8;
$card-title: #8d8d8d;

$text-section-label-background: rgba(155, 210, 206, 0.3);
$text-section-description-color: rgba(0, 0, 0, 0.7);

$accordion-background: rgba(155, 210, 206, 0.3);

$profile-settings-text-color: rgba(97, 96, 114, 1);
$profile-settings-text-color-active: rgba(28, 81, 88, 1);
$profile-settings-border-color: rgba(152, 152, 152, 1);
$profile-settings-border-color-active: rgba(28, 81, 88, 1);
$profile-container-background: rgba(255, 255, 255, 1);
$profile-container-shadow: 0rem 0rem 0.625rem 0rem rgba(0, 0, 0, 0.05);
$social-login-button: #9bd2ce33;
$social-login-button-hover: #75979533;

$auth-backdrop-background-color: rgba(1, 11, 11, 0.35);
$very-light-black-color: rgba(0, 0, 0, 0.05);

$profile-my-settings-border-color: rgba(230, 230, 230, 1);
$date-selection-month: #11141a;
$date-selection-month-disabled: rgba(17, 20, 26, 0.4);
$date-border: #bbc5d5;
$date-minutes: #354052;
$card-hover-color: rgba(155, 210, 206, 0.2);
$card-sold-out: #eb4335;
$card-text-active: #f4f5f6;
$card-text-inactive: rgba(244, 245, 246, 0.7);
$session-title: #616072;
$summary-title: #050505;
$summary-divider: rgba(28, 81, 88, 0.8);
$total-pay: #929292;
$remove-button: #a41517;
$discount-placeholder: #808080;

$success-background: rgba(155, 210, 206, 0.3);
$success-color: #1c5158;

$error-background: rgba(255, 60, 0, 0.2);
$error-color: #eb4335;

$session-accordion-title-color: rgba(17, 20, 26, 1);
$session-accordion-price-color: rgba(28, 81, 88, 1);
$session-accordion-duration-color: rgba(53, 64, 82, 1);
$session-accordion-svg-rect-color: rgba(235, 246, 245, 1);
$session-accordion-svg-path-color: rgba(22, 22, 52, 1);

$session-info-border: 0.0625rem solid rgba(0, 0, 0, 0.15);
$session-info-modal-title-color: rgba(17, 20, 26, 1);
$session-info-modal-price-color: rgba(28, 81, 88, 1);
$session-info-modal-duration-color: rgba(53, 64, 82, 1);
$session-info-modal-icon-color: rgba(155, 210, 206, 1);
$session-info-modal-icon-color-hover: rgb(137, 185, 182);

$subscription-alert-background: rgba(155, 210, 206, 0.3);

$subscription-card-icon-background: rgba(155, 210, 206, 0.3);
$subscription-card-info-color: rgba(53, 64, 82, 1);

$homepage-contact-image-border-color: rgba(28, 81, 88, 1);

$failure-color: #ad3113;

$profile-label-color: #11141a;

$pagination-item-border: #f1f1f1;
$pagination-selected-background: #1c5158;

$public-table-border-head: rgba(34, 51, 84, 0.1);
$public-table-text-color: rgba(34, 51, 84, 0.7);
$public-table-text-hover: rgb(99, 115, 129);
$public-table-row-color: rgb(33, 43, 54);
$public-table-border-color: #ebebeb;
$public-table-row-border-color: #dadada;
$public-table-head-color: #a4a4a4;

$my-coupons-button-background: #1c5158;
$my-coupons-card-background: rgba(156, 211, 207, 0.3);
$my-coupons-card-shadow: #0000000d;
$my-coupons-card-balance-color: #161634;

$input-border-focused-admin: $primary-light;

$my-reservations-title: #101928;
$my-reservations-pill-success-background: #9bd2ce4d;

$public-date-picker-background: #f5f5f5;
$public-date-picker-arrow: #a0e7e5;
$public-date-picker-typography: #141414;

$opening-offer-text-shadow-color: #7fdfe3;
$opening-offer-main-text-container-background: rgba(127, 223, 227, 0.5);
$opening-offer-button-background: #0e6e72;
$opening-offer-button-background-hover: #15878c;
